<template>
  <div class="video-wrapper">
    <VideoBar :courseName="studentData.courseTitle" :courseId="studentData.id" :student="studentData.name" />
    <div class="content-wrapper">
      <div class="video">
        <video controls ref="video">
          <source :src="videoUrl" type="video/mp4">
        </video>
      </div>
      <div class="menu">
        <h3>{{ studentData.courseTitle }}</h3>
        <div class="name">
          <span>目录</span>
        </div>
        <el-scrollbar class="u-scroll">

        <ul class="menu-ul">
          <li v-for="(item,index) in menuList" :key="index" class="menu-item" @click.stop="fold(index)">
            <div class="menu-title">
              <div>{{ index + 1 }}.</div>
              <div>{{ item.title }}</div>
            </div>
            <ul class="course-ul" v-if="item.fold">
              <li class="course" v-for="(course,_index) in item.children" :key="_index"
                  @click.stop="getSelected(course.taskId,course.materialType,course.material,course.canSee)"
                  :class="{active:course.taskId===selectedCourse}">
                <div class="courseName">
                  <img src="../../../assets/image/cammer.png" alt="">
                  <div class="title">{{ course.title }}</div>
                </div>
                <div class="time">
                  <span>{{ course.duration }}</span>
                </div>
              </li>
            </ul>
          </li>

        </ul>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import VideoBar from '@/components/VideoBar'
import { pushCourseTask,getTaskByApplyId,getMyClassDetail } from '@/api/course'
export default {
  components: { VideoBar },
  data() {
    return {
      studentId: this.$route.query.studentId,
      studentData:{},
      menuList: [],
      selectedMenu:this.$route.query.selectedMenu,
      selectedCourse:this.$route.query.selectedCourse,
      videoUrl:'',
      schedule:0,
      duration:null,
    }
  },
  watch: {
    videoUrl(newValue, oldValue) {
      let video = this.$refs.video;
      video.src = this.videoUrl;
      this.videoInit()
    }
  },

  mounted() {
    // this.studentData = JSON.parse(this.studentData)
    // this.menuList = this.studentData.courseDirectorys

    this.videoUrl=this.$route.query.url
    this.init()
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    init(){
      getTaskByApplyId(this.studentId).then((res)=>{
        if(res.code===200){
          console.log(res.data,111)
          this.studentData= res.data
          this.menuList=res.data.courseDirectorys
          this.menuList.map((item)=>{
            this.$set(item,'fold',false)
            item.children.map((course)=>{
              if(course.pid===this.selectedMenu){
                item.fold=true
              }
            })
          })
        }
      })
    },
    refreshMenu(){
      getTaskByApplyId(this.studentId).then((res)=>{
        if(res.code===200){
          this.menuList=res.data.courseDirectorys
          this.menuList.map((item)=>{
            this.$set(item,'fold',false)
            item.children.map((course)=>{
              if(course.pid===this.selectedMenu){
                item.fold=true
              }
            })
          })

        }
      })
    },
    videoInit(){
      let video = this.$refs.video
      video.addEventListener('loadedmetadata', () => {
        //视频的总长度
        console.log('总长度:' + video.duration);
        this.duration=video.duration
        clearInterval(this.timer);
      });
      video.addEventListener('playing', () => {
        //播放中
        console.log('播放中');
        this.timer = setInterval(() => {
          this.schedule=parseFloat(video.currentTime)/this.duration *100
          console.log('播放进度:' + this.schedule);
          let params={
            sch:this.schedule,
            taskId:this.selectedCourse
          }
          pushCourseTask(params).then((res)=>{
            if(res.code===200){
              console.log('上传成功')
            }
          })
        }, 30000);
      });
      video.addEventListener(
          'ended',
          () => {
            //结束
            console.log('播放结束');
            let params={
              sch:100,
              taskId:this.selectedCourse
            }
            pushCourseTask(params).then((res)=>{
              if(res.code===200){
                console.log('上传成功100')
                this.refreshMenu()

              }
            })
            clearInterval(this.timer);
          },
          false
      );

    },
    fold(index){
      this.menuList[index].fold = this.menuList[index].fold === false;
    },
    getSelected(taskId,type,material,canSee){
      if(canSee){
        this.selectedCourse=taskId
        material=JSON.parse(material)
        let url = this.loadUrl(material[0].url)
        if(type==1){
          this.videoUrl=url
        }else {
          this.videoUrl=''
          // window.location.href=url
          window.open(url)
          let params={
            sch:100,
            taskId:taskId
          }
          pushCourseTask(params).then((res)=>{
            if(res.code===200){
              console.log('上传成功100')
              this.refreshMenu()

            }
          })
        }
      }else {
        this.$alert('无法点击该节课，请完成前置学习', {
          confirmButtonText: '确定',
        });
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.video-wrapper {
  height: 100%;
  padding-top: 60px;
  box-sizing: border-box;
  background-color: #191919;
}

.content-wrapper {
  display: flex;
  height: 100%;

  .video {
    width: 85%;

    video {
      width: 100%;
      height: 100%;
    }
  }

  .menu {
    width: 15%;
    background-color: #2D2D2D;
    border-left: 1px solid #1E1E1E;
    overflow: hidden;

    //overflow: auto;

    h3 {
      padding: 18px 30px;
      background-color: #333333;
      font-size: 20px;
      color: #999999;
    }

    .name {
      padding: 18px 30px 0 30px;
      font-size: 16px;
      color: #FFFFFF;
      background-color: #2D2D2D;
      border-bottom: 1px solid #26262B;

      span {
        display: inline-block;
        padding-bottom: 18px;
        border-bottom: 1px solid #1E50AE;
      }
    }

    .menu-ul {
      //padding: 0 18px;
      background-color: #2D2D2D;
      .menu-item {
        border-bottom: 1px solid #333;
        cursor: pointer;
      }

      .menu-title {
        display: flex;
        align-items: center;
        padding: 26px 12px 10px 30px;
        color: #999999;
        width: 100%;
      }

      .course-ul {
        padding: 0 18px;

        .course {
          color: #999999;
          padding: 16px 12px;
          cursor: pointer;
          &.active{
            background: #1E1E1E;
            border-radius: 4px;
          }
          .courseName {
            display: flex;
            align-items: center;
            font-size: 14px;
            padding-bottom: 10px;

            img {
              width: 13px;
              height: 13px;
              margin-right: 13px;
            }
          }

          .time {
            font-size: 12px;
            margin-left: 26px;
          }

        }

      }
    }
  }
}
</style>

