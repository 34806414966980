<template>
  <div class="user-header">
    <h1 class="user-logo">
      <span class="user-text" @click="goBack">返回</span>
      <span class="user-text">|</span>
      <span class="user-text" @click="golink">{{student}}</span>
    </h1>
    <div class="wrapper">
      <span class="bar-text"><i class="el-icon-chat-dot-round"></i>咨询老师</span>
      <user-bar />
    </div>
  </div>
</template>
<script>
import { getTenant } from "@/api/user.js";
import UserBar from "@/components/UserBar";
export default {
  name: "UserHeader",
  components: {
    UserBar,
  },
  props:['student','courseName',"courseId"],
  data() {
    return {
      logo: "",
      type: "",
      rehref: "",
      tenant_id: "",
    };
  },
  mounted() {
    // this.getTenantLogo();
  },
  methods: {
    // 获取租户的信息
    getTenantLogo() {
      const self = this;
      let ip = window.location.href.split("/#")[0];
      getTenant().then((res) => {
        if (res.code == 200 && res.data) {
          this.type = res.data.type;
          this.logo = res.data.loginLogo;
          this.rehref =
              res.data.type == 1
                  ? `${ip}/#/template-first`
                  : res.data.type == 2
                  ? `${ip}/#/template-second`
                  : res.data.type == 3
                      ? `${ip}/#/template-third`
                      : "";
        } else {
          // self.$message(res.msg);
        }
      });
    },
    golink() {
      if (this.type != "") {
        window.location.href = this.rehref;
      } else {
        this.$router.push("/index");
      }
    },
    goBack(){
      this.$router.go(-1)
    }
  },
};
</script>

<style lang="scss" scoped>
.user-header {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  //border-bottom: 1px solid #ececec;
  display: flex;
  background: #191919;
  align-items: center;
  justify-content: space-between;
  .user-logo {
    line-height: 60px;
    display: flex;
    padding-left: 20px;
    align-items: center;
    cursor: pointer;
    a,
    img {
      width: 176px;
      display: inline-block;
      line-height: 34px;
    }
  }
  .user-text {
    font-size: 16px;
    font-weight: 400;
    color: #AAAAAA;
    padding-left: 23px;
  }

  .top-menu {
    padding-right: 20px;
    .icon-touxiang {
      font-size: 40px;
    }
    .el-dropdown {
      cursor: pointer;
    }
    .login-link {
      display: inline-block;
      padding-left: 20px;
      font-size: 14px;
      color: #666;
      span {
        font-weight: 400;
        color: #333333;
        cursor: pointer;
        &:hover {
          color: #1e50ae;
        }
      }
    }
  }
  .wrapper{
    display: flex;
    align-items: center;
    .bar-text{
      padding-right: 20px;
      color: #AAAAAA;
    }
  }
}
</style>


